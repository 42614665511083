import React from "react";
import { Link } from "gatsby";
import Icon from "@/components/Icon";


export default function ListNote({ edges }) {
  return (
    <>
      {edges.map((edge) => (
        <div className="listnote">
          <div className="listnote__image">
            <Link to={`/${edge.node.fields.slug}`}>
              <img src={edge.node.frontmatter.image} />
            </Link>
          </div>


          <div className="listnote__text">
            <Link to={`/${edge.node.fields.slug}`}>
              <h2 className="listnote__title">{edge.node.fields.title}</h2>
            </Link>
            
            <p className="listnote__excerpt">
              {edge.node.excerpt || edge.node.frontmatter.description}
            </p>

          </div>

        </div>
      ))}
    </>
  );
}
